import { useState } from "react";
import { db } from "../../firebaseConfig";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import "./Newsletter.css";
import "../Root.css";

export default function Newsletter() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubscribe = async () => {
    try {
      await addDoc(collection(db, "subscriptions"), {
        email: email,
        timestamp: serverTimestamp(),
      });
      setMessage("Subscription successful. Check your email.");
    } catch (e) {
      console.error("Error adding document: ", e);
      setMessage("Subscription failed.");
    }
  };

  return (
    <div className="newsletter-section">
      <div className="newsletter-left">
        <h2 className="newsletter-title">
          Subscribe For <span className="newsletter-title-green">New Content</span>
        </h2>
        <p className="newsletter-text">
          Le lorem ipsum est, en imprimerie, une suite de mots sans signification{" "}
        </p>
      </div>
      <div className="newsletter-right">
        <div className="newsletter-right-box">
          <input
            placeholder="Email"
            className="newsletter-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <button className="newsletter-btn" onClick={handleSubscribe}>
            Subscribe
          </button>
          {message && <p>{message}</p>}
        </div>
      </div>
    </div>
  );
}
