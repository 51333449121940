import AboutSection from "../components/home/AboutSection"
import BlogSection from "../components/home/BlogSection"
import Hero from "../components/home/Hero"
import Newsletter from "../components/home/Newsletter"
import PortfolioSection from "../components/home/PortfolioSection"
import ServicesSection from "../components/home/ServicesSection"
import Testimonials from "../components/home/Testimonials"
import Navbar from "../components/Navbar"
import Footer from "../components/Footer"

export default function Home() {
    return (
        <>
            <Navbar />
            <Hero />
            <ServicesSection />
            <PortfolioSection />
            <AboutSection />
            <BlogSection />
            <Testimonials />
            <Newsletter />
            <Footer />
        </>
    )
}