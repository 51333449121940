import './Footer.css';
import './Root.css';

export default function Footer() {
    return (
        <>
            <p>Footer</p>
       
        </>
    )
}