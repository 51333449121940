import "./ServicesSection.css";

export default function ServicesSection() {
  return (
    <div className="servicessection-section">
      <div className="servicessection-top">
        <h2 className="servicessection-title">
          My <span className="servicessection-title-green">Services</span>
        </h2>
        <p className="servicessection-text">
          Le lorem ipsum est, en imprimerie, une suite de mots sans
          signification utilisée à titre provisoire pour calibrer
        </p>
      </div>
      <div className="servicessection-bottom">
        <div className="servicessection-box">
          <div className="servicessection-bg-grey">
            <div className="servicessection-box-title-box">
              <i class="bi bi-code-square servicessection-box-icon-green"></i>
              <h3 className="servicessection-box-title">Design</h3>
            </div>
            <p className="servicessection-box-text">
              Le lorem ipsum est, en imprimerie, une suite de mots sans Le lorem
              ipsum est, en imprimerie, une suite de mots sans
            </p>
          </div>
          <img
            src="./images/servicessection-placeholder.png"
            className="servicessection-img"
          ></img>
          <a href="/services" className="servicessection-btn-grey">
            <i class="bi bi-arrow-up-right"></i>
          </a>
        </div>

        <div className="servicessection-box">
          <div className="servicessection-bg-green">
            <div className="servicessection-box-title-box">
              <i class="bi bi-code-square servicessection-box-icon-grey"></i>
              <h3 className="servicessection-box-title">Design</h3>
            </div>
            <p className="servicessection-box-text">
              Le lorem ipsum est, en imprimerie, une suite de mots sans Le lorem
              ipsum est, en imprimerie, une suite de mots sans
            </p>
          </div>
          <img
            src="./images/servicessection-placeholder.png"
            className="servicessection-img"
          ></img>
          <a href="/services" className="servicessection-btn-green">
            <i class="bi bi-arrow-up-right"></i>
          </a>
        </div>

        <div className="servicessection-box">
          <div className="servicessection-bg-grey">
            <div className="servicessection-box-title-box">
              <i class="bi bi-code-square servicessection-box-icon-green"></i>
              <h3 className="servicessection-box-title">Design</h3>
            </div>
            <p className="servicessection-box-text">
              Le lorem ipsum est, en imprimerie, une suite de mots sans Le lorem
              ipsum est, en imprimerie, une suite de mots sans
            </p>
          </div>
          <img
            src="./images/servicessection-placeholder.png"
            className="servicessection-img"
          ></img>
          <a href="/services" className="servicessection-btn-grey">
            <i class="bi bi-arrow-up-right"></i>
          </a>
        </div>
      </div>
    </div>
  );
}
