// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC7ouBtUwfEgUiVhh_iJHQZKwr4M3dpP6w",
  authDomain: "portolio-1-f.firebaseapp.com",
  projectId: "portolio-1-f",
  storageBucket: "portolio-1-f.appspot.com",
  messagingSenderId: "232524561",
  appId: "1:232524561:web:cbb325659b7330efac9ab0",
  measurementId: "G-1NNT96RP98"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const functions = getFunctions(app);

export { db, functions };