import React, { useEffect, useState } from "react";
import "./PortfolioSection.css";
import "../Root.css";

const slides = [
    { id: 1, image: "./images/portfolio-etienne.png", title: "Portfolio Etienne Dubois", description: "Luxurious portfolio combining passion for diving and product ownership.", link: "https://etiennedubois.fr/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 2, image: "./images/portfolio-3s.png", title: "Third Space", description: "Website for a no-code web3 builder.", link: "https://3rd-space.io/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 3, image: "./images/portfolio-victor.png", title: "Portfolio Victor Bisch", description: "Minecraft-themed portfolio adapted for mobile format.", link: "https://portfoliovictor.com/eng/index.html", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 4, image: "./images/portfolio-smm.png", title: "Self Mad Man", description: "Landing page for a caricature mobile game.", link: "https://selfmadman.fr/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 5, image: "./images/portfolio-courtialet.png", title: "Le Courtialet", description: "Website for a naturist campsite with booking section.", link: "https://lecourtialet.fr/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 6, image: "./images/portfolio-nono.png", title: "Portfolio Nolann Gerbault", description: "Clash Royale-themed portfolio showcasing main game screens.", link: "https://nolanncorp.com/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 7, image: "./images/portfolio-idem.png", title: "Idem Automatisme", description: "Website for an industrial engineering company highlighting services.", link: "https://www.idem-automatisme.fr/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 8, image: "./images/portfolio-tran.png", title: "Maître Tran", description: "Website for a French lawyer showcasing her career.", link: "https://www.trantt-avocat.fr/", labels: ["Web Development"] },
    { id: 9, image: "./images/portfolio-naturismera.png", title: "Naturisme en Rhône-Alpes", description: "Website for the regional naturism federation in Rhône-Alpes.", link: "https://www.naturismerhonealpes.fr/", labels: ["Branding", "Web Design", "Web Development"] },
    { id: 10, image: "./images/portfolio-antyl.png", title: "Antyl", description: "Website for an urbanism company focused on AI (hackathon project).", link: "https://antyl.webflow.io/", labels: ["Branding", "Web Design", "Web Development"] },
  ];

const PortfolioSection = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides.length * 100));
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % (slides.length * 100));
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? (slides.length * 100 - 1) : (prevSlide - 1)
    );
  };

  const visibleSlides = [];
  for (let i = 0; i < 100; i++) {
    visibleSlides.push(...slides);
  }

  return (
    <div className="portfoliosection-section">
      <div className="portfoliosection-top">
        <h2 className="portfoliosection-top-title">
          Let's Have a Look at my 
          <span className="portfoliosection-top-title-green"> Portfolio</span>
        </h2>
        <a href="/portfolio" className="portfoliosection-cta-green">
          See More<i className="bi bi-arrow-up-right"></i>
        </a>
      </div>
      <div className="portfoliosection-bottom">
        <button className="portfolio-prev" onClick={prevSlide}><i class="bi bi-arrow-left"></i></button>
        <div className="portfolio-slider">
          {visibleSlides.slice(currentSlide, currentSlide + 3).map((slide, index) => (
            <div key={index} className="portfolio-slide">
              <div className="portfolio-slide-content">
                <img src={slide.image} alt={slide.title} className="portfolio-slide-image" />
                <div className="portfolio-slide-overlay">
                  <h3 className="portfolio-slide-title">{slide.title}</h3>
                  <p className="portfolio-slide-description">{slide.description}</p>
                  <div className="portfolio-slide-labels">
                    {slide.labels.map((label, idx) => (
                      <span key={idx} className="portfolio-slide-label">{label}</span>
                    ))}
                  </div>
                  <a href={slide.link} className="portfolio-slide-button" target="_blank" rel="noopener noreferrer">Visit Website <i class="bi bi-arrow-right"></i></a>
                
                </div>
              </div>
            </div>
          ))}
        </div>
        <button className="portfolio-next" onClick={nextSlide}><i class="bi bi-arrow-right"></i></button>
      </div>
    </div>
  );
}

export default PortfolioSection;
